import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import logoSchool from '../../styles/imgs/koorsK.png'
import defaultUser from '../../styles/imgs/Default_user_icon.svg';
import TooltipCustomizedElement from '../../elements/tooltip/Tooltip-Customized-element';
import TooltipCustomized from '../../UI/TooltipCustomized';
import { getSchoolInfo } from '../../global-state/actions/app-container-actions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { withTranslation } from 'react-i18next';
const SchoolProfSectionComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [schoolLogo, setschoolLogo] = useState(logoSchool)
    let professorBio = useMemo<string>(() => {
        return props?.profData?.bio;
    }, [props?.profData])

    useEffect(() => {
      if(props?.schoolInfo?.logo !== undefined && props?.schoolInfo?.logo?.length!=0){
        setschoolLogo(props?.schoolInfo?.logo)
      }
    }, [props?.schoolInfo])

    

    
    
    return <>
        <section className="d-none d-xl-block" style={(!!props?.schoolInfo?.color) ? { background: 'linear-gradient(to right, ' + props?.schoolInfo?.color + ' 50%, transparent 50%)' } : { background: 'linear-gradient(to right, #212529 50%, transparent 50%)' }}>

            <div style={{ minHeight: 300, maxWidth: 1200, margin: 'auto' }}>
                <div className="d-xl-flex align-items-start w-100 px-2">
                    <div className="w-100 d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>
                        <p className="H3-Headline my-5" style={{ color: '#FFFFFF' }}> {t('aboutSchool')} </p>
                        <div style={{
                            backgroundColor: `rgba(255,255,255, 0.7)` ,
                        }}>
                            <img style={{ height: 120, maxWidth: 400 , objectFit: 'fill'  }} src={schoolLogo} alt="logo school" />
                        </div>
                        <TooltipCustomized content={props?.schoolInfo?.shortDescription}>
                            <p className="body-md text-center mt-4 mb-5 px-5 paragraph-five-lines" style={{ color: '#FFFFFF' }}> {props?.schoolInfo?.shortDescription} </p>
                        </TooltipCustomized>
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-center flex-column px-5" style={{ minHeight: 300 }}>
                        <p className="px-5 H3-Headline black-800 my-5">{t('aboutProfessor')}</p>
                        <img style={{ width: 120, height: 120, objectFit: 'fill', borderRadius: '50%' }} src={(props?.photoProf && props?.photoProf !== null && props?.photoProf !== '') ? props?.photoProf : defaultUser} alt="user icon" />
                        <TooltipCustomizedElement content={professorBio}>
                            <p className="px-5 body-md neutral-2 text-center mt-4 mb-5 paragraph-five-lines">
                                {professorBio}
                            </p>
                        </TooltipCustomizedElement>
                    </div>
                </div>
            </div>
        </section>

        <section className="d-xs-block d-xl-none">
                    <div className="w-100 d-flex align-items-center justify-content-center flex-column" style={{ minHeight: 300, background : props?.schoolInfo?.color }}>
                        <p className="H3-Headline my-5" style={{ color: '#FFFFFF' }}> {t('aboutSchool')} </p>
                        <div style={{
                            backgroundColor: `rgba(255,255,255, 0.7)` ,
                        }}>
                            <img style={{ height: 120, maxWidth: 400 , objectFit: 'fill'  }} src={schoolLogo} alt="logo school" />
                        </div>
                        <TooltipCustomized content={props?.schoolInfo?.shortDescription}>
                            <p className="body-md text-center mt-4 mb-5 px-3 px-md-0 max-width" style={{ color: '#FFFFFF' }}> 
                                {props?.schoolInfo?.shortDescription} 
                            </p>
                        </TooltipCustomized>
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-center flex-column" style={{ minHeight: 300 }}>
                        <p className="H3-Headline black-800 my-5">{t('aboutProfessor')}</p>
                        <img style={{ width: 120, height: 120, objectFit: 'fill', borderRadius: '50%' }} src={(props?.photoProf && props?.photoProf !== null && props?.photoProf !== '') ? props?.photoProf : defaultUser} alt="user icon" />
                        <TooltipCustomizedElement content={professorBio}>
                            <p className="px-3 px-md-0 body-md neutral-2 text-center mt-4 mb-5 max-width">
                                {professorBio}
                            </p>
                        </TooltipCustomizedElement>
                    </div>
        </section>
    </>
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    photoProf: ownProps?.photoProf,
    profData: ownProps?.profData,
    connectedUser: state.authReducer.connectedUser,
    connectedSchool: state.authReducer.connectedSchoolInfo,
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            getSchoolInfo
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(SchoolProfSectionComponent));
